<template>
  <div class="field-section">
    <label class="text-caption grey--text text--darken-2">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="subtitle-2">
      <slot v-if="slotIn || value" name="value">
        {{ value }}
      </slot>
      <div v-else-if="!value">
        <i class="text-caption grey--text text--darken-1"> sem informações </i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slotIn: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
