<template>
  <div class="page-header text-center text-lg-left">
    <div
      class="font-weight-bold"
      :class="$vuetify.breakpoint.smAndUp ? 'title' : 'body-1'"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
      type: String,
    },
  },
};
</script>
