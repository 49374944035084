<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :style="`margin: ${margin}; background: transparent; display: block`"
      :width="width + 'px'"
      :height="height + 'px'"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
    <g class="ldl-scale" style="transform-origin: 50% 50%; transform: rotate(0deg) scale(0.8, 0.8);">
      <g class="ldl-ani"
         style="transform-origin: 50px 50px;transform: translate(0px, 0px) rotate(0deg) scale(1, 1) skewX(0deg) skewY(0deg);opacity: 1;animation: 2s linear 1s infinite normal forwards running SvgSlideLeftToRight">
        <g class="ldl-layer"><g class="ldl-ani" style="transform-origin: 50px 50px;"><path fill="#e0e0e0" d="M77.48 28.757L61.231 12.508c-1.191-1.166-2.28-1.727-3.276-1.732H22.589c-.943 0-1.711.762-1.711 1.698v75.051c0 .936.768 1.698 1.711 1.698h54.822c.943 0 1.711-.762 1.711-1.698V32.033c.009-.998-.508-2.085-1.642-3.276z" style="fill: rgb(224, 224, 224);">
        </path>
        </g>
        </g>
        <g class="ldl-layer"><g class="ldl-ani" style="transform-origin: 50px 50px;">
    <path fill="#666" d="M62.414 28.757H77.48L61.231 12.508v15.066c0 .652.531 1.183 1.183 1.183z"
          style="fill: rgb(102, 102, 102);">
    </path>
        </g>
        </g>
        <g class="ldl-layer"><g class="ldl-ani" style="transform-origin: 50px 50px;">
    <path fill="#323232"
          d="M80.461 26.424L62.256 8.286a2.676 2.676 0 0 0-1.902-.786H22.589c-2.75 0-4.987 2.232-4.987 4.975v75.051c0 2.743 2.237 4.975 4.987 4.975h54.822c2.75 0 4.987-2.232 4.987-4.975V31.085a6.536 6.536 0 0 0-1.937-4.661zm-2.981 2.333H62.414a1.185 1.185 0 0 1-1.183-1.183V12.508L77.48 28.757zm-.069 60.467H22.589a1.707 1.707 0 0 1-1.711-1.698V12.475c0-.936.768-1.698 1.711-1.698h35.366v16.798c0 2.459 2 4.459 4.459 4.459h16.708v55.493c0 .935-.767 1.697-1.711 1.697zm3.05-62.8L62.256 8.286a2.676 2.676 0 0 0-1.902-.786H22.589c-2.75 0-4.987 2.232-4.987 4.975v75.051c0 2.743 2.237 4.975 4.987 4.975h54.822c2.75 0 4.987-2.232 4.987-4.975V31.085a6.536 6.536 0 0 0-1.937-4.661zm-2.981 2.333H62.414a1.185 1.185 0 0 1-1.183-1.183V12.508L77.48 28.757zm-.069 60.467H22.589a1.707 1.707 0 0 1-1.711-1.698V12.475c0-.936.768-1.698 1.711-1.698h35.366v16.798c0 2.459 2 4.459 4.459 4.459h16.708v55.493c0 .935-.767 1.697-1.711 1.697zm-8.286-44.891H40.863v-3.778h28.262v3.778zm0 9.445H30.639V50h38.486v3.778zm0 9.444H30.639v-3.778h38.486v3.778zm0 9.445H30.639v-3.778h38.486v3.778zm0 9.444H30.639v-3.778h38.486v3.778z"
          style="fill: rgb(50, 50, 50);">

    </path></g></g></g></g></svg>
</template>

<script>
export default {
  props: {
    margin: {
      default: "0",
      type: String,
    },
    width: {
      default: "200",
      type: String,
    },
    height: {
      default: "200",
      type: String,
    },
  },
};
</script>
