<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      :style="`margin: ${margin}; background: transparent; display: block`"
      :width="width + 'px'"
      :height="height + 'px'"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
  >
    <g
      class="ldl-scale"
      style="transform-origin: 50% 50%; transform: rotate(0deg) scale(0.8, 0.8)"
    >
      <g
        class="ldl-ani"
        style="
          transform-origin: 50px 50px;
          transform: translate(0px, 0px) rotate(0deg) scale(1, 1) skewX(0deg)
            skewY(0deg);
          opacity: 1;
          animation: 2s linear 0s infinite normal forwards running SvgSlideLeftToRight"
      >
        <g class="ldl-layer">
          <g class="ldl-ani">
            <g id="XMLID_128_">
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M7.495 35.815h28.333V7.5H7.495v28.315zm3.4-24.917H32.43v21.519H10.895V10.898z"
                    id="XMLID_129_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M14.861 14.862h13.597v13.591H14.861z"
                    id="XMLID_132_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M92.495 7.5H64.162v28.315h28.333V7.5zm-3.399 24.917H67.561V10.898h21.535v21.519z"
                    id="XMLID_133_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M71.527 14.862h13.597v13.591H71.527z"
                    id="XMLID_137_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M7.495 92.456h28.333V64.141H7.495v28.315zm3.4-24.917H32.43v21.519H10.895V67.539z"
                    id="XMLID_138_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M14.861 71.503h13.597v13.591H14.861z"
                    id="XMLID_143_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M45.273 7.518h9.444v9.44h-9.444z"
                    id="XMLID_144_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M45.282 26.419h9.444v9.44h-9.444z"
                    id="XMLID_145_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M16.949 45.299h9.444v9.44h-9.444z"
                    id="XMLID_146_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M64.171 45.299H35.838v9.44H54.727v9.44H73.616v-9.44h-9.445z"
                    id="XMLID_147_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M73.616 45.299h9.444v9.44h-9.444z"
                    id="XMLID_148_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M45.282 64.179h9.444v9.44h-9.444z"
                    id="XMLID_156_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M54.727 73.62h9.444v9.44h-9.444z"
                    id="XMLID_163_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M45.282 83.06h9.444v9.44h-9.444z"
                    id="XMLID_164_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M73.616 83.06v9.44H92.505v-9.44H83.06v-9.44h-9.444z"
                    id="XMLID_166_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g class="ldl-ani" style="transform-origin: 50px 50px">
                  <path
                    d="M83.06 54.739V73.62h9.445V54.739z"
                    id="XMLID_167_"
                    style="fill: rgb(40, 41, 47)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    margin: {
      default: "0",
      type: String,
    },
    width: {
      default: "200",
      type: String,
    },
    height: {
      default: "200",
      type: String,
    },
  },
};
</script>

