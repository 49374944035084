<template>
  <div class="page-section">
    <div class="mt-2 grey lighten-5">
      <v-row no-gutters>
        <v-col class="pb-1">
          <div v-if="groupLabel">
            <v-divider class="white"></v-divider>
            <h6
              class="
                mt-1
                d-inline-block
                grey--text
                text--darken-3 text-body-2
                font-weight-bold
                px-2
              "
            >
              {{ groupLabel }}
            </h6>
            <v-divider v-if="groupLabel" class="orange lighten-3 mb-2 mx-2" />
          </div>
          <div class="px-2 pb-3">
            <slot />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groupLabel: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
