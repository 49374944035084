<template>
  <div class="home-details">
    <page-header :title="title" />
    <page-section>
      <div v-if="error">
        <v-row>
          <v-col>
            <p class="red--text">
              {{ error }}
            </p>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <div v-if="countCheckStatus >= 3">
          <span class="red--text">
            O processo excedeu o limite de espera, contate a equipe de suporte
            do sistema para verificar se houve erro interno no servidor.
          </span>
        </div>
        <v-row v-if="countCheckStatus < 3 && !isNew && !generating">
          <v-col cols="10" lg="6">
            <div v-if="qrcode.statusData === 0 && qrcode.data.length > 0">
              <small>Último arquivo gerado</small>
              <v-row class="pt-3">
                <v-col cols="2" lg="1">
                  <v-icon>mdi-file</v-icon>
                </v-col>
                <v-col>
                  <v-row no-gutters>
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3"
                      >Inicio:</v-col
                    >
                    <v-col class="caption pr-3 pb-1" cols="12" lg="9">{{
                      new Date(qrcode.data[0].inicioProcesso).toLocaleString()
                    }}</v-col>
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3"
                      >Término:</v-col
                    >
                    <v-col class="caption pr-3 pb-1" cols="12" lg="9">{{
                      new Date(qrcode.data[0].fimProcesso).toLocaleString()
                    }}</v-col>
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3"
                      >Tipo:</v-col
                    >
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3">{{
                      getType(qrcode.data[0].tipo)
                    }}</v-col>
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3"
                      >Quantidade:</v-col
                    >
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3">{{
                      qrcode.data[0].quantidade
                    }}</v-col>
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3"
                      >Status:</v-col
                    >
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3">
                      <v-badge
                        :content="
                          qrcode.data[0].status === 'DONE'
                            ? 'concluído'
                            : 'erro'
                        "
                        dark
                        :color="
                          qrcode.data[0].status === 'DONE' ? 'success' : 'error'
                        "
                        offset-y="8"
                        offset-x="0"
                      />
                    </v-col>
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3"
                      >Lote Status:</v-col
                    >
                    <v-col class="caption pr-3 pb-1" cols="12" lg="3">
                      <v-badge
                        :content="
                          qrcode.data[0].loteStatus === 'OK' ? 'ok' : 'erro'
                        "
                        dark
                        :color="
                          qrcode.data[0].loteStatus === 'OK'
                            ? 'success'
                            : 'error'
                        "
                        offset-y="8"
                        offset-x="0"
                      />
                    </v-col>
                    <v-col class="caption pt-3 pb-1" lg="12">
                      <v-btn
                        v-if="
                          qrcode.data[0].loteStatus === 'OK' &&
                          qrcode.data[0].status === 'DONE'
                        "
                        x-small
                        color="grey darken-1"
                        dark
                        :href="getURLDowloadFile(qrcode.data[0].arquivo)"
                      >
                        <v-icon small>mdi-download</v-icon>
                        Baixar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="2" lg="6">
            <v-layout justify-end class="pt-3 px-2">
              <v-btn small color="primary" @click="isNew = true">Novo</v-btn>
            </v-layout>
          </v-col>
        </v-row>
        <v-row v-else-if="countCheckStatus < 3 && isNew && !generating">
          <v-col cols="5" lg="3">
            <field-section slot-in>
              <template #value>
                <v-text-field
                  v-model="quantity"
                  class="mt-4"
                  outlined
                  dense
                  label="Quantidade"
                  :rules="[(v) => v <= 10000 || 'Limite máximo é 10.000']"
                  :error-messages="
                    !isQuantValid ? ['digite a quantidade'] : null
                  "
                  background-color="white"
                  @input="isQuantValid = true"
                />
              </template>
            </field-section>
          </v-col>
          <v-col cols="7" lg="4">
            <field-section slot-in>
              <template #value>
                <v-select
                  v-model="typeSelected"
                  class="mt-4"
                  label="Tipo"
                  :items="types"
                  :error-messages="!isTypeValid ? ['selecione o tipo'] : null"
                  outlined
                  dense
                  background-color="white"
                  @change="isTypeValid = true"
                >
                  <template #item="{ item, on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.text | toPT_BRCapitalize
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </template>
            </field-section>
          </v-col>
          <v-col cols="12" class="pt-0">
            <field-section slot-in>
              <template #value>
                <v-btn small class="px-8" color="primary" @click="onGenerate">
                  Gerar
                </v-btn>
                <v-btn small class="ml-4" @click="isNew = false">
                  Cancelar
                </v-btn>
              </template>
            </field-section>
          </v-col>
        </v-row>
        <v-row
          v-else-if="countCheckStatus < 3 && generating && isNew"
          class="pt-4"
        >
          <v-col cols="4" class="d-flex align-center">
            <v-layout row class="ma-0 pa-0">
              <div v-if="status === 0" class="body-2">
                Status:
                <v-progress-circular
                  indeterminate
                  color="blue"
                  class="mb-1 ml-2 mr-1"
                  size="15"
                  width="2"
                />
                <b>Gerando arquivo...</b>
              </div>
              <div v-else class="body-2">
                Status:
                <v-icon color="success" class="mb-1" size="18"
                  >mdi-check-circle</v-icon
                >
                <b>Concluído com Sucesso</b>
              </div>
            </v-layout>
          </v-col>
          <v-col>
            <v-layout
              v-if="status === 0"
              column
              justify-start
              class="pa-0 ma-0"
              fill-height
            >
              <v-layout row class="pa-0 ma-0" justify-start align-start>
                <svg-qrcode
                  :width="svgWidth"
                  :height="svgHeight"
                  style="border-left: solid #666"
                />
                <svg-gears
                  :width="svgWidth"
                  :height="svgHeight"
                  style="border: solid #999 1px"
                />
                <svg-file
                  :width="svgWidth"
                  :height="svgHeight"
                  style="border-right: solid #666"
                />
              </v-layout>
            </v-layout>
          </v-col>
        </v-row>
      </div>
    </page-section>
    <page-section group-label="Histórico de QR Codes Gerados">
      <v-row no-gutters>
        <v-col class="mb-4 overflow-x-auto">
          <div class="table-container">
            <v-data-table
              :headers="headers"
              :header-props="headerProps"
              :items="qrcode.data"
              :search="tableSearch"
              :loading="qrcode.statusData === 1"
              fixed-header
              item-key=""
              disable-pagination
              hide-default-footer
            >
              <template #item.cliente="{ item }">
                {{
                  (item.cliente ? item.cliente.nomeFantasia : "My Life Click")
                    | toPT_BRCapitalize
                }}
              </template>
              <template #item.tipo="{ item }">
                <v-icon v-text="getIconType(getType(item.tipo))" class="mr-2" />
                {{ getType(item.tipo) }}
              </template>
              <template #item.status="{ item }">
                <v-progress-circular
                  v-if="item.status === 'RUNNING' && countCheckStatus < 3"
                  color="blue"
                  width="2"
                  size="20"
                  indeterminate
                  title="em andamento"
                />
                <v-icon
                  v-if="item.status === 'DONE'"
                  color="success"
                  title="concluído"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="countCheckStatus >= 3"
                  color="error"
                  title="houve falha ao gerar o arquivo"
                >
                  mdi-close-circle
                </v-icon>
              </template>
              <template #item.loteStatus="{ item }">
                <v-icon
                  v-if="item.loteStatus === 'OK'"
                  color="success"
                  title="OK"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="item.loteStatus === 'FAIL'"
                  color="error"
                  title="houve falha ao gerar o lote"
                >
                  mdi-close-circle
                </v-icon>
                <v-icon v-else color="warning" title="status não indentificado">
                  mdi-alert-circle
                </v-icon>
              </template>
              <template #item.arquivo="{ item }">
                <v-icon
                  v-if="item.loteStatus === 'FAIL'"
                  color="error"
                  title="não gerou arquivo"
                >
                  mdi-file-remove-outline
                </v-icon>
                <v-btn
                  v-if="
                    !!getURLDowloadFile(item.arquivo) &&
                    item.loteStatus === 'OK'
                  "
                  icon
                  :href="getURLDowloadFile(item.arquivo)"
                >
                  <v-icon title="baixar o arquivo">mdi-download</v-icon>
                </v-btn>
                <v-icon v-else title="link não encontrado">mdi-link-off</v-icon>
              </template>
              <template #item.pdf="{ item }">
                <v-icon
                  v-if="item.loteStatus === 'FAIL'"
                  color="error"
                  title="não gerou arquivo"
                >
                  mdi-file-remove-outline
                </v-icon>
                <v-btn
                  target="_blank"
                  icon
                  :href="getURLDowloadPDFFile(item.arquivo)"
                >
                  <div title="baixar em PDF" style="position: relative">
                    <v-icon size="29">mdi-file-outline</v-icon>
                    <v-icon
                      color="default"
                      style="position: absolute; top: 3px; right: 0"
                      size="14"
                      >mdi-file-pdf-box</v-icon
                    >
                  </div>
                </v-btn>
                <v-icon
                  v-if="!getURLDowloadPDFFile(item.arquivo)"
                  title="link não encontrado"
                  >mdi-link-off</v-icon
                >
              </template>
              <template #item.visualizar="{ item }">
                <v-icon
                  v-if="item.loteStatus === 'FAIL'"
                  color="error"
                  title="não gerou arquivo"
                >
                  mdi-file-remove-outline
                </v-icon>
                <v-btn
                  v-if="
                    item.quantidade === 1 &&
                    !!getURLDowloadFile(item.arquivo) &&
                    item.loteStatus === 'OK'
                  "
                  icon
                  :to="`/visualizar-qrcode?type=${item.tipo}&uid=${item.uid}`"
                >
                  <div style="position: relative">
                    <v-icon title="ver imagem QR Code">mdi-qrcode</v-icon>
                  </div>
                </v-btn>
                <v-btn
                  v-if="
                    item.quantidade > 1 &&
                    !!getURLDowloadFile(item.arquivo) &&
                    item.loteStatus === 'OK'
                  "
                  target="_blank"
                  icon
                  :href="item.arquivo"
                >
                  <v-icon title="abra o arquivo">mdi-open-in-new</v-icon>
                </v-btn>
                <v-icon
                  v-if="!getURLDowloadFile(item.arquivo)"
                  title="link não encontrado"
                  >mdi-link-off</v-icon
                >
              </template>
              <template #item.tempo="{ item }">
                {{ getDuration(item.inicioProcesso, item.tempo) }}
              </template>
              <template #item.inicioProcesso="{ item }">
                {{
                  item.inicioProcesso
                    ? new Date(item.inicioProcesso).toLocaleString()
                    : ""
                }}
              </template>
              <template #item.fimProcesso="{ item }">
                {{
                  item.fimProcesso
                    ? new Date(item.fimProcesso).toLocaleString()
                    : ""
                }}
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </page-section>
  </div>
</template>

<script>
import { baseURL } from "@/store/api";
import PageHeader from "@/components/headers/page-header";
import PageSection from "@/components/sections/page-section";
import FieldSection from "@/components/sections/field-section";
import SvgGears from "@/components/svgs/svg-gears";
import SvgQrcode from "@/components/svgs/svg-qrcode";
import SvgFile from "@/components/svgs/svg-file";
import moment from "moment";
export default {
  metaInfo: {
    titleTemplate: "Gerar QR Codes | %s",
  },
  components: {
    SvgFile,
    SvgQrcode,
    SvgGears,
    FieldSection,
    PageSection,
    PageHeader,
  },
  data: () => ({
    title: "Gerar QR Codes",
    tableSearch: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    headers: [
      { text: "ID", value: "id" },
      { text: "Cliente", value: "cliente", with: 210 },
      { text: "Tipo", value: "tipo", width: 200 },
      { text: "Qtd.", value: "quantidade" },
      { text: "Status", value: "status" },
      { text: "Lote Status", value: "loteStatus" },
      { text: "Arquivo", value: "arquivo" },
      { text: "PDF", value: "pdf" },
      { text: "Visualizar", value: "visualizar" },
      { text: "Início", value: "inicioProcesso", with: 250 },
      { text: "Fim", value: "fimProcesso", width: 250 },
    ],
    types: [
      {
        icon: "mdi-account",
        text: "Bracelete - My Life Click",
        value: "pessoa",
      },
      { icon: "mdi-dog-side", text: "Pingente - My Life Click", value: "pet" },
    ],
    svgWidth: "40",
    svgHeight: "40",
    quantity: "",
    typeSelected: "",
    generating: false,
    status: 0,
    isNew: false,
    isTypeValid: true,
    isQuantValid: true,
    error: "",
    timer: {},
    countCheckStatus: 0,
  }),
  computed: {
    qrcode() {
      return this.$store.state.qrcode;
    },
  },
  mounted() {
    this.getCheckStatus();
    this.getTypes();
  },
  methods: {
    getTypes() {
      this.$store.dispatch("qrcode/getTypes", {
        callbackSuccess: (data) => {
          data.forEach((e) => {
            if (JSON.parse(e.tagTipos).includes("bracelete")) {
              this.types.push({
                icon: "mdi-account",
                text:
                  "Bracelete - " +
                  this.$options.filters.toPT_BRCapitalize(e.nomeFantasia),
                value: "pessoa-" + e.tag,
              });
            }
            if (JSON.parse(e.tagTipos).includes("pingente")) {
              this.types.push({
                icon: "mdi-dog-side",
                text:
                  "Pingente - " +
                  this.$options.filters.toPT_BRCapitalize(e.nomeFantasia),
                value: "pet-" + e.tag,
              });
            }
            if (JSON.parse(e.tagTipos).includes("tag")) {
              this.types.push({
                icon: "mdi-tag",
                text:
                  "Tag - " +
                  this.$options.filters.toPT_BRCapitalize(e.nomeFantasia),
                value: "tag-" + e.tag,
              });
            }
          });
        },
      });
    },
    getURLDowloadFile(S3Path) {
      return S3Path
        ? baseURL +
            "api/qrcode/download/" +
            (S3Path.split("download/")[1] || S3Path.split("testeDownload/")[1])
        : null;
    },
    getURLDowloadPDFFile(S3Path) {
      return S3Path
        ? baseURL +
            "api/qrcode/pdf/download/" +
            (
              S3Path.split("download/")[1] || S3Path.split("testeDownload/")[1]
            ).replace(".txt", ".zip")
        : null;
    },
    onGenerate() {
      if (!this.quantity) return (this.isQuantValid = false);

      if (!this.typeSelected) return (this.isTypeValid = false);

      this.generating = true;

      this.countCheckStatus = 0;

      this.status = 0;

      this.$store
        .dispatch("qrcode/getGenerate", {
          type: this.typeSelected,
          quantity: this.quantity,
        })
        .then(() => {
          setTimeout(() => {
            this.$store
              .dispatch("qrcode/getData", {})
              .then((data) => {
                clearInterval(this.timer);

                if (data.length > 0 && data[0].status === "RUNNING") {
                  this.generating = true;
                  this.isNew = true;

                  this.timer = setInterval(() => {
                    this.getCheckStatus();
                    this.countCheckStatus++;
                  }, 30000);
                } else {
                  this.status = 1;
                  this.generating = false;
                  this.isNew = false;
                  this.countCheckStatus = 0;
                }
              })
              .catch(() => {
                location.reload();
              });
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          if (err) {
            if (err.status === 404) {
              return (this.error =
                "Erro: serviço indisponível, tente recarregar a página");
            } else if (err.status >= 500) {
              return (this.error =
                "Erro: serviço falhou ao processar os dados.");
            }
          }
          this.error =
            "Erro: houve um erro inesperado, tente recarregar a página.";
        });
    },
    getDuration(startTime, totalTime) {
      const duration = moment.duration(
        new moment(startTime)
          .add(totalTime, "seconds")
          .diff(new moment(startTime))
      );
      return (
        Math.ceil(duration.hours()).toString().padStart(2, "0") +
        ":" +
        Math.ceil(duration.minutes()).toString().padStart(2, "0") +
        ":" +
        Math.ceil(duration.seconds()).toString().padStart(2, "0")
      );
    },
    getCheckStatus() {
      this.$store
        .dispatch("qrcode/getData", {})
        .then((data) => {
          this.generating = false;
          this.quantity = undefined;
          this.typeSelected = undefined;

          clearInterval(this.timer);

          if (data.length > 0 && data[0].status === "RUNNING") {
            this.generating = true;
            this.isNew = true;

            this.timer = setInterval(() => {
              this.getCheckStatus();
              this.countCheckStatus++;
            }, 30000);
          } else {
            this.status = 1;
            this.generating = false;
            this.isNew = false;
            this.countCheckStatus = 0;
          }
        })
        .catch((err) => {
          console.error(err);
          if (err) {
            if (err.status === 404) {
              return (this.error =
                "Erro: serviço indisponível, tente recarregar a página");
            } else if (err.status >= 500) {
              return (this.error =
                "Erro: serviço falhou ao processar os dados.");
            }
          }
          this.error =
            "Erro: houve um erro inesperado, tente recarregar a página.";
        });
    },
    getType(type) {
      if (/^pet/g.test(type)) return "Pingente";
      else if (/^pessoa/g.test(type)) return "Bracelete";
      else if (/^tag/g.test(type)) return "Tag";
      return "";
    },
    getIconType(type) {
      if (/Pingente/g.test(type)) return "mdi-dog-side";
      else if (/Bracelete/g.test(type)) return "mdi-account";
      else if (/Tag/g.test(type)) return "mdi-tag";

      return "";
    },
  },
};
</script>

<style lang="scss">
.home-details {
  @media (min-width: 1025px) {
    .table-container {
      height: 320px;
      min-height: 320px;
      max-height: 320px;
      min-width: 1420px;
    }
    .v-data-table {
      height: 320px !important;
    }
  }
  @media (max-width: 600px) {
    .table-container {
      max-height: calc(100vh - 170px);
    }
    .v-data-table {
      height: calc(100vh);
    }
  }
}
</style>
